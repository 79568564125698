

body, html {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto';
  font-family: 'Montserrat';
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/robo/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}