.topNav {

  font-Size: 13px;
  background-color: darkorange;
  color: black;
  font-weight: 700;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-Index: 999;
  height: 8vh;
  display: flex;
  align-items: center;


}

.navPos {
  top: 8vh;

}

.logo {

  width: 15vh;
}

.homeSectionPos {
  top: 6vh;
  position: relative;
}

.serviceTop10 {
  top: 6vh;
  position: relative;
}

.homeReviewNum {
  top: 6vh;
  height: 12vh;
  width: 90%;
  position: relative;
  padding: 20px;
  text-align: center;

}

.centerContent {

  position: relative;
  text-align: center;
  font-size: 29px;


}

.sectionRClients {
  margin-top: 38px;
  padding-top: 110px;
  padding-bottom: 110px;
  text-align: center;
  background-color: orange;
}

.sectionHomeAbout {

  padding-top: 110px;
  padding-bottom: 50px;
  text-align: center;
  background-color: #09497a;



  color: white;
}

.imgShadow {
  box-shadow: 5px 3px 19px grey;
  border-radius: 5px;

}

.sectionFooter {
  padding: 60px;

  background-color: #242424;
  margin: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: relative;
  color: white;
}

.btnS {
  padding: 15px;

  background-color: transparent;
  color: yellow;
  border-radius: 15px;
  text-align: center;
  margin: 0 auto;
  transition: .8s;

}

.btnS:hover {
  color: whitesmoke;
  background-color: #052944;
  text-align: center;
}

.ptext {
  font-size: 12px;
  color: yellow;

}

.ptext2 {
  font-size: 13px;
  color: silver;
  padding-bottom: 50px;
}

.menuUL ul li {

  font-size: 14px;
  color: silver;
  list-style: none;
  transition: .8s;

}

.menuUL ul li:hover {
  text-decoration: none;
  color: yellow;

}

.copyText {
  padding-bottom: 50px;
  font-size: 11px;
  color: silver;
  bottom: 0px;
}


.popkornlab {
  font-size: 10px;
  color: yellow;
  float: right;
}

.popkornlab a {
  font-size: 10px;
  text-decoration: none;
  color: yellow;

}

.logoFooter {
  width: 18vh;
  float: right;
}

.top10 {

  font-size: 30px;
  background-color: darkorange;
  padding: 5px;
  color: black;
  border-radius: 10px;
  font-weight: 700;
}

/* ---------------- Services ---------------------- */

.sectionServices {

  background-color: #cc6104;
  color: white;
  height: auto;
  top: 6vh;
  padding: 100px;
  position: relative;

}

.sectionAbout {

  background-color: #cc6104;
  color: white;
  height: auto;
  top: 6vh;
  padding: 100px;
  position: relative;

}

.sectionContact {

  background-color: white;
  color: rgb(44, 43, 43);
  height: auto;
  top: 6vh;
  padding: 100px;
  position: relative;

}




.cardHeader {
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.cardTitle {
  color: #052944;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.cardText {
  color: #242424;
  font-size: 17px;

  text-align: center;
}

.headingCommon {
  font-weight: 700;
  color: #eb5e0b;
  line-height: 35px;
  list-style: none;
}


.smallText {
  font-weight: 700;
  color: #350b40;
  line-height: 25px;
  list-style: none;
  font-size: 15px;
}

.spacer {
  padding-top: 20px;
  padding-bottom: 20px;
}




.serviceGST {


  color: black;
  height: auto;
  top: 6vh;
  padding: 80px;
  position: relative;

}

.docList li {

  color: grey;
  font-size: 16px;
  list-style: none;
}

@media only screen and (max-width: 680px) {
  p {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .serviceGST
  {
    padding: 5px;
  }

  .sectionAbout, .sectionServices, .sectionContact
  {
    padding: 20px;


  } 

  .gridMob{
    text-align: -webkit-center;
  }

  .logoFooter{

    margin-right: 30%;
  }

  .topNav {
  
    height: 10vh;
    display: block;

}
}
